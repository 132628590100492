<template>
  <v-container :style="{'max-width': settingsTableWider ? '1700px' : '1500px'}">
    
    <keep-alive>
      <Search ref="searchAbonents" :searchGroupData="searchGroupData" v-on:clear-pre-search="prefixSearch = ''" v-on:apply-search="startSearch" v-on:change-search-group="changeSearchGroup" :searchHint="searchHint" :prefixSearch="prefixSearch"/>
    </keep-alive>
    <keep-alive >
      <Filters ref="filtersAbonents" v-on:apply-filters="checkGetAbonents()" for="abonents"/>
    </keep-alive>

    <v-data-table
      v-if="$route.name == 'abonents'"
      :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
      :dense="settingsTableDanse || $vuetify.breakpoint.smAndDown"
      :hide-default-footer="loading || loadingBilling"
      :server-items-length="paginations.count"
      :items-per-page="getItemsPerPage()"
      :options.sync="options"
      :headers="headersList"
      :footer-props="footer"
      :loading="loading"
      :page.sync="page"
      :items="abonents"
      no-data-text="Немає даних для відображення"
      class="elevation-1 row-cursor"
      loading-text="Завантаження..."
      ref="AbonentsTable"
      focusable
      @click:row="onClickRow"
    >
      <template v-slot:top>
        <v-toolbar  :extended="$vuetify.breakpoint.xs" flat color="secondary lighten-1" dark> 
          <template v-slot:extension v-if="$vuetify.breakpoint.xs && firstRequest">
            <v-spacer />
              <NavigationButtons :options="options" :paginations="paginations" :disabledButtons="loading || loadingBilling" v-on:prev-page="page -= 1" v-on:next-page="page += 1"/>
            <v-spacer />
          </template>

          <!------------------------------------------------------------------------------------------>
          
          <template v-if="!settingsTableBar">
            <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"><h2 class="accent--text">Абоненти</h2></v-toolbar-title>
            <v-icon v-if="checkReqBilling" title="Поточний запит до біллінгу" class="ml-2" color="blue">mdi-web</v-icon>
            <v-spacer />
            <template v-if="!$vuetify.breakpoint.xs && firstRequest">
              <NavigationButtons :options="options" :paginations="paginations" :disabledButtons="loading || loadingBilling" v-on:prev-page="page -= 1" v-on:next-page="page += 1"/>
            </template>
            <v-spacer />
            <v-btn v-if="user && user.user_type != 2"
              title="Додати Абонента"
              icon  
              @click="$refs.addAbonent.show('add')"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn 
              :color="settingsTableWider ? 'accent' : ''"
              title="Більше данних"
              icon
              @click="settingsTableWider = !settingsTableWider">
                <v-icon>mdi-unfold-more-vertical</v-icon> 
            </v-btn>
            <v-btn 
              title="Налаштування таблиці"
              icon
              @click="settingsTableBar = !settingsTableBar"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>   
            <v-btn title="Поновити данні" icon :loading="loading || loadingBilling" @click="getAbonents()">
              <v-icon>autorenew</v-icon>
            </v-btn>
            <v-btn title="Фільтри пошуку"  icon @click="$refs.filtersAbonents.show()">
              <v-badge
                :value="countFilters > 0"
                :content="countFilters"
                color="accent"
                text="primary"
                dark
              >
              <v-icon>mdi-filter</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <!------------------------------------------------------------------------------------------>

          <template v-else> 
            <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp"><h4>Налаштування</h4></v-toolbar-title>
            <v-spacer />
            <v-btn 
              :color="settingsTableDanse ? 'accent' : ''"
              title="Збільшити/зменшити інтервал між рядками"
              class="mr-3"
              outlined
              small
              @click="settingsTableDanse = !settingsTableDanse"
            >
              <v-icon left>mdi-arrow-collapse-vertical</v-icon>
              Рядки
            </v-btn>
            <v-btn 
              :color="settingsTableWider ? 'accent' : ''"
              title="Збільшити/зменшити ширину таблиці"
              class="mr-3"
              outlined
              small
              @click="settingsTableWider = !settingsTableWider"
            >
              <v-icon left>mdi-unfold-more-vertical</v-icon>
              Ширше
            </v-btn>  
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  title="Редагування відображення колонок"
                  class="mr-4"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  dark
                >
                <v-icon left >mdi-view-column</v-icon>
                  Колонки
                </v-btn>
              </template>
              <v-sheet max-height="500" style="overflow-y: auto;">
                <v-list dense > 
                  <v-list-item>
                    <v-btn
                      :disabled="!changeHeaders"
                      color="primary"
                      x-small
                      block
                      @click="saveHeaders()"
                    >
                      Зберегти {{settingsTableWider ? 'для розширеної' : 'за замовчуванням' }} 
                    </v-btn>
                    </v-list-item>
                    <v-list-item>
                    <v-btn
                      color="error"
                      outlined
                      x-small
                      block
                      @click="getDefaultHeaders()"
                    >
                      Скинути
                    </v-btn>
                  </v-list-item>
                  <v-divider />
                  <draggable v-model="headers" :animation="200" handle=".handle">
                    <v-list-item v-for="(item, index) in headers" :value="item.value" :key="index" >
                      <v-list-item-icon class="mr-1"><v-icon small v-if="item.view">mdi-check</v-icon></v-list-item-icon>
                      <v-list-item-title class="handle-click" @click="item.view = !item.view">{{ item.text }}</v-list-item-title>
                      <v-list-item-icon class="ml-6"><v-icon small class="handle">mdi-drag</v-icon></v-list-item-icon>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-sheet>
            </v-menu>
            <v-btn icon @click="settingsTableBar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        
        <!------------------------------------------------------------------------------------------>
        </v-toolbar>
      </template>

      <template v-slot:header="{ props: { headers } }" v-if="countFilters > 0">
        <thead>
          <tr>
            <th :colspan="headers.length">
              Фільтри: 
              <keep-alive v-if="filtersComponents">
                <FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :is_close="true" v-on:open-filters="$refs.filtersAbonents.show()"/>
              </keep-alive>
            </th>
          </tr>
        </thead>
      </template>

    <!-- ---------------------------------------- Cols ----------------------------------------- -->
      <template #item.index="{ item }" style="background: #eee">
        {{ abonents.indexOf(item) + paginations.start_index }}
      </template>

      <template #item.full_name="{ item }"> 
        <div :class="{'d-flex justify-space-between align-center' : !$vuetify.breakpoint.mobile }">
          <span v-if="item.is_organization" :title="`Організація: ${item.organization || 'Без назви'} \nПІБ в організації: ${item.full_name || 'Без імені'}`">
            {{ item.organization || item.full_name }}
            <v-chip v-if="item.billing_data && item.billing_data.real_ip" :title="`Реальна IP адреса:\n${item.billing_data.real_ip}`" color="blue" x-small class="px-2 white--text">Real IP</v-chip>
          </span>
          <span v-else>
            {{ item.full_name}} 
            <v-chip v-if="item.billing_data && item.billing_data.real_ip" :title="`Зарезервована IP адреса:\n${item.billing_data.real_ip}`" color="blue" x-small class="px-2 white--text">Real IP</v-chip>
          </span>
          <div v-if="item.is_organization" :class="{'d-flex justify-end' : $vuetify.breakpoint.mobile}" class="ml-2">
            <v-chip  :title="`Організація: ${item.organization || 'Без назви'} \nПІБ в організації: ${item.full_name || 'Без імені'}`" color="orange" x-small class="px-2 white--text">ОРГ</v-chip>
          </div>
        </div>
        <span v-if="!item.id" class="grey--text">Абонента не знайдено</span>
      </template>

      <template #item.street__street="{ item }">
        <span :title="item.street && item.street.street_view">{{ item.street && item.street.street || ''}}</span>
      </template>

      <template #item.house="{ item }">
        {{ item && `${item.house || ''}${item.letter || ''}`}}{{ item && item.corpus > 0 && ` (к.${item.corpus})` || '' }}
      </template>

      <template #item.flat="{ item }">
        {{ item && item.flat }}{{ item && item.tenand > 0 && ` (н.${item.tenand})` || '' }}
      </template>
      
      <template #item.operator_group="{ item }">
        <template v-if="item && item.operator_group">
          {{ item.operator_group.name }}
        </template>
        <template v-else-if="item && item.billing_data">
          {{ operators.find(x => x.id == item.billing_data.operator).operator_group_name }}
        </template>
      </template>

      <template #item.status="{ item }"> 
        <ColumnServices columnName="status" :item="item" />
      </template>

      <template #item.discount="{ item }"> 
        {{ item.billing_data && item.billing_data.discount && item.billing_data.discount + '%' || ''}}
      </template>

      <template #item.balance="{ item }"> 
        <ColumnServices columnName="balance" :item="item"  />
      </template>

      <template #item.tarif="{ item }">
        <ColumnServices columnName="tarif" :item="item" :rates="rates"/>
      </template>

      <template #item.last_login="{ item }">
        <span style="white-space: nowrap;" v-if="item.billing_data && item.billing_data.last_login" :title="item.billing_data.last_login">{{ item.billing_data.last_login.split(' ')[0] }} </span>
      </template>

      <template #item.user_phones="{ item }">
        <v-badge
          v-if="item.user_phones"
          :content="`+${item.user_phones.length - 1}`"
          :value="item && item.user_phones.length > 1"
          :inline="item.user_phones.length > 1"
          color="grey"
          overlap
        >
          {{item && item.user_phones.length != 0 && item.user_phones[0].phone || '' }}
        </v-badge> 
        <!--{{item && item.user_phones.length != 0 && item.user_phones.find(x => x.main).phone || '' }} <b>{{ item && item.user_phones.length > 1 && ` (+${item.user_phones.length - 1})` || '' }}</b>-->
      </template>

      <template #item.user_id="{ item }">
        <span class="d-flex justify-start"  v-if="item.user_service && item.user_service.find(x => x.service == 1)" >
          <v-icon style="vertical-align: baseline;" x-small  color="grey" class="mr-1">mdi-television</v-icon> {{ item.id }}
        </span>
        <span class="d-flex justify-start"  v-if="item.billing_data && item.billing_data.user_id">
          <v-icon style="vertical-align: baseline;" x-small  color="grey" class="mr-1">mdi-web</v-icon> {{ item.billing_data.user_id }}
        </span>
      </template>

      <template #item.tags="{ item }">
        <template v-for="tag in item.tags">
          <v-icon :key="tag.id" :title="tag.name" :color="tag.color" class="mr-1">
            mdi-tag
          </v-icon>
        </template>
      </template>

      <!-- ---------------------------------------- /Cols ----------------------------------------- -->
      <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
        {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
      </template>
    
    </v-data-table>
    
    <keep-alive >
      <AddAbonentModal ref="addAbonent"/>
    </keep-alive>
    
  </v-container> 
</template>

<script>
  import axios from "axios"
  import Search from '@/components/Abonents/Search.vue'
  //import AddAbonentModal from '@/components/Abonents/AddAbonentModal.vue'
  //import Filters from '@/components/Abonents/Filters.vue'
  import FiltersChips from '@/components/Abonents/FiltersChips.vue'
  import ColumnServices from '@/components/Abonents/ColumnServices.vue'
  //import NavigationButtons from '@/components/Abonents/NavigationButtons.vue'
  import searchParse from "@/components/Abonents/searchParse.js"
  import searchGroupData from "@/components/Abonents/searchGroupData.js"

  import { settingsTable } from '@/mixins/settingsTable.js'
  import draggable from "vuedraggable"

  import { mapGetters } from "vuex"
  export default {
    //props: [ 'search' ],
    name: 'abonents-table',
    mixins: [settingsTable],
    components: {
      Search,
      AddAbonentModal: () => import('@/components/Abonents/AddAbonentModal.vue'),
      Filters: () => import('@/components/Abonents/Filters.vue'),
      FiltersChips,
      ColumnServices,
      NavigationButtons: () => import('@/components/Abonents/NavigationButtons.vue'),
      draggable
    },
    data: () => ({
      selectedItem: [],
      searchGroupNow: searchGroupData[0],
      searchGroupData: searchGroupData,
      checkReqBilling: false,
      mountedTable: false,
      firstRequest: false,
      prefixSearch: '',
      showGlobalSearch: false,
      search: '',
      searchHint: '',
      abonents: [],
      paginations: {},
      countFilters: 0,
      loading: false,
      loadingBilling: false,
      options: {},
      page: 1,
      filtersComponents: [],
      headers: [],
      headersSortDefault:{
        'default': [{"value":"index","view":true},{"value":"user_id","view":false},{"value":"username","view":false},{"value":"full_name","view":true},{"value":"street__street","view":true},{"value":"house","view":true},{"value":"flat","view":true},{"value":"operator_group","view":true},{"value":"status","view":true},{"value":"discount","view":false},{"value":"balance","view":true},{"value":"tarif","view":true},{"value":"last_login","view":true},{"value":"user_phones","view":true},{"value":"tags","view":true}],
        'more': [{"value":"index","view":true},{"value":"user_id","view":true},{"value":"username","view":true},{"value":"full_name","view":true},{"value":"street__street","view":true},{"value":"house","view":true},{"value":"flat","view":true},{"value":"operator_group","view":true},{"value":"status","view":true},{"value":"discount","view":true},{"value":"balance","view":true},{"value":"tarif","view":true},{"value":"last_login","view":true},{"value":"user_phones","view":true},{"value":"tags","view":true}],
      },
      headersDefault: [
        { text: '#', value: 'index', sortable: false,  width: 50, view: true},
        { text: 'Ос.рахунок', value: 'user_id', sortable: false, view: false },
        { text: 'Логін', value: 'username', sortable: false, view: false },
        { text: 'П.І.Б. / Назва Ор.', value: 'full_name', sortable: false, view: true },
        { text: 'ВУЛИЦЯ', value: 'street__street', view: true },
        { text: 'БУД', value: 'house',  sortable: false, view: true},
        { text: 'КВ', value: 'flat',  sortable: false, view: true},
        { text: 'ОПЕРАТОР', value: 'operator_group', view: true},
        { text: 'ПОСЛУГА', value: 'status', sortable: false, view: true},
        { text: 'ЗНИЖКА', value: 'discount', class: 'text-end', cellClass: 'text-end', view: false },
        { text: 'БАЛАНС', value: 'balance', class: 'text-end', sortable: false, view: true },
        { text: 'ТАРИФ', value: 'tarif', sortable: false, view: true },
        { text: 'ВХІД', value: 'last_login', view: true},
        { text: 'ТЕЛЕФОН', value: 'user_phones',  sortable: false, view: true},
        { text: 'ТЕГ', value: 'tags',  sortable: false, view: true},
      ],
      footer: {
          "items-per-page-options": [10,25,50],
          "items-per-page-text": " ", //"Абонентів на сторінці:"
          "items-per-page-all-text": "Всі",
          "show-first-last-page": true,
          "show-current-page": true,
      },
    }),
    computed: {
      ...mapGetters({
        rates: "filters/rates",
        //streets: "filters/streets",
        operators: "filters/operators", 
        user: "auth/user"
      }),
    },
    mounted (){
      this.$nextTick(function () {
        this.createHeadersTable()
        this.mountedTable = true
        //console.log(this.$route.params.search)
      })
    },
    watch: {
      /*search: function(){
        this.checkGetAbonents()
      },*/
      options: {
        handler (val, oldVal) {
          //console.log(this)
          //console.log("oldVal.page:", oldVal.page, oldVal, "val.page: ", val.page, val )
          oldVal.page && this.getAbonents();

          /**------ Зберігаємо до локально сховища зміну відображення кількості елементів на сторінці  -------*/

          if(oldVal.itemsPerPage && oldVal.itemsPerPage != val.itemsPerPage){
            if(this.$vuetify.breakpoint.mobile){
              localStorage.setItem("setting-table-abonent-itemsPerPage-mobile", val.itemsPerPage) 
              this.$router.app.settingTableAbonent.itemsPerPage_mobile = val.itemsPerPage
            }else{
              localStorage.setItem("setting-table-abonent-itemsPerPage", val.itemsPerPage) 
              this.$router.app.settingTableAbonent.itemsPerPage = val.itemsPerPage
            }
          } 
          /**---------------------------------------------------------------------------------- */
        },
        deep: true,
      },
      
    },
    methods: {
      searchParse: searchParse,
      
      getItemsPerPage(){
        /** Функція попереднього встановлення кількості елементів в таблиці відносно локального сховища та типу приладу */

        if(this.$vuetify.breakpoint.mobile){ 
          return this.$router.app.settingTableAbonent.itemsPerPage_mobile && Number(this.$router.app.settingTableAbonent.itemsPerPage_mobile) || 10
        }else{
          return this.$router.app.settingTableAbonent.itemsPerPage && Number(this.$router.app.settingTableAbonent.itemsPerPage) || 10
        } 
      },
      startSearch: function(search){
        //console.log("startSearch")
        this.search = search 
        this.checkGetAbonents()
        
      },
      changeSearchGroup(searchGroup){
        //console.log("searchGroup: ", searchGroup)
        this.searchGroupNow = this.searchGroupData[searchGroup]
        this.prefixSearch = this.searchGroupNow.prefix
      },
      createHeadersTable(){
        /*let operatorIndex = this.headers.indexOf(this.headers.find(x => x.value == "operator_group")) 
        let operatorIndexMore = this.moreHeaders.indexOf(this.moreHeaders.find(x => x.value == "operator_group")) 
        
        if(this.user?.operator_id == 1){ // якщо оператор "Altair"
          //
        }else{
          this.headers.splice(operatorIndex, 1)
          this.moreHeaders.splice(operatorIndexMore, 1)
        }*/
        
      },
      onClickRow(item){
        item.id && this.$router.push({ name: 'abonent_info', params: { id: item.id }})
      },
      showRouter(){
        //console.log(this.$refs)
      },
      checkGetAbonents(){
        if(this.options.page == 1){
          //console.log("checkGetAbonents")
          this.getAbonents();
        }else{
          this.page = 1;
        }
      },
      /*
       
       */
      async getAbonentsBillingFirst(){
        this.loading = true
        this.loadingBilling = true
        this.showGlobalSearch = false
        this.checkReqBilling = true
        try {
          const { data } = await axios.get(this.$router.app.devUrlBillingAPI, {params: {req_cmd: "getAbonents",  ...this.filterFormat()}});
          if(data.resp_status == "OK"){
            this.abonents = data.resp_result.result
            /*data.resp_result.result.forEach(elem => {
              this.abonents.push({billing_data: elem})
            })*/
            this.paginations = data.resp_result.paginations
            this.getAbonentsSecond()
            //this.abonents = data.resp_result
            this.searchHint = data.resp_result.result.length
          }else {
            this.abonents = []
            this.paginations = {}
            this.searchHint = ''
            this.loading = false
            this.$router.app.$sheet.show("Помилка!", data.resp_errmsg)
          }
          this.loadingBilling = false
        }catch(err){
          this.loadingBilling = false
          this.$router.app.$sheet.show("Помилка Достуту в біллінг!", err);
        }
      },
      getAbonentsSecond(){
        if(!this.abonents){return}
        let pcodes = []
        //this.abonents.forEach(elem => {pcodes += elem.billing_data.pcode + ','})
        this.abonents.forEach(elem => {pcodes.push(elem.billing_data.pcode)})
        axios({
          method: "post",
          url: "/api/cabletv/get_users_pcode/",
          data: {user_pcode_list: pcodes}
        })
        .then(response => {
          if(response.data){
            if(response.data.length > 0){
              response.data.forEach(elem => {
                delete elem.billing_data
                Object.assign(this.abonents.find(x => x.billing_data.pcode == elem.user_pcode), elem)})
            }
          }
        })
        .catch(err => {
          //console.log(err);
          this.$router.app.$sheet.show("Помилка пошуку абонентів!", err);
        })
        .finally(() => (this.loading = false))
      },
      checkTypeRequestBilling(){
        if(this.search){
          this.searchParse(this.search, this.searchGroupNow.name)
        }
        const service = this.$refs.filtersAbonents?.filters.find(x => x.name == "service").value
        const status = this.$refs.filtersAbonents?.filters.find(x => x.name == "status").value
        const tarif = this.$refs.filtersAbonents?.filters.find(x => x.name == "tarif").value
        const discount = this.$refs.filtersAbonents?.filters.find(x => x.name == "discount").value
         
        //const is_online = this.$refs.filtersAbonents?.filters.find(x => x.name == "is_online").value
        const is_credit = this.$refs.filtersAbonents?.filters.find(x => x.name == "is_credit").value
        //console.log(is_credit)
        console.log(this.prefixSearch)
        return this.options.sortBy == 'last_login'
        ||
        this.options.sortBy == 'discount'
        ||
        service == 2
        ||
        status && (status == 'active' || status == 'not_active')
        ||
        tarif && this.rates.find(x => x.id == tarif).service == 2
        ||
        is_credit && is_credit != "credit_tv"
        ||
        this.prefixSearch == 'по mac kom: '
        ||
        discount
        ||
        false
         
      },
      async getAbonents(){
          //console.log("start getAbonents")
          this.firstRequest = true
          if(this.checkTypeRequestBilling()) {
          //console.log("checkTypeRequestBilling getAbonents")
            this.getAbonentsBillingFirst()
            return
          } 
          
          this.checkReqBilling = false
          //console.log('next code')
          //console.log(this.$route.params)
          //if(this.loading == true || this.loadingBilling == true) return
          this.loading = true
          this.loadingBilling = true
          this.showGlobalSearch = false
          //console.log("pre request getAbonents")
          try {
            const { data } = await axios.get('/api/cabletv/users/', {params: this.filterFormat()});
            //console.log("get data request getAbonents")
            this.paginations = data.paginations
            this.abonents = data.results
            this.searchHint = data.paginations.count
            //console.log(data)
            if(this.abonents.length == 0) this.showGlobalSearch = true
            this.getAbonentsBillingSecond() // Робимо запит на данні абонентів з буллінгу
            this.loading = false
            
          } catch (error) {
            console.log("eror request getAbonents", error)
            //console.log(error)
            this.loading = false
            this.paginations = []
            this.abonents = []
            this.loadingBilling = false
          }    
      },
      getAbonentsBillingSecond(){
        if(!this.abonents){return}
        let pcode_list = []
        this.abonents.filter(x => x.user_service && x.user_service.length > 0 && x.user_service.find(y => y.service == 2)).forEach(element => {pcode_list.push(element.user_pcode)})
        //console.log("logins_list: ", logins_list)
        if(pcode_list.length == 0){
          this.loadingBilling = false
          return
        }
        axios({
          method: "post",
          url: this.$router.app.devUrlBillingAPI,
          //url: "/api_death_day/",
          data: {req_cmd: "getUsersListInfo", pcode_list: pcode_list}
        })
          .then(response => {
            
            if(response.data.resp_status == "OK"){
              if(response.data.resp_result.length > 0){
                response.data.resp_result.forEach(abonent_billing => {this.abonents.find(x => x.user_pcode == abonent_billing.pcode).billing_data = abonent_billing})
              }
            }else{
              //
            }
          
            //console.log(response)
            
            
          })
          .catch(err => {
            //console.log(err);
            this.$router.app.$sheet.show("Помилка Достуту в біллінг!", err);
          })
          .finally(() => (this.loadingBilling = false))
      },
      clearFilter(filter_name){
        this.$refs.filtersAbonents.filters.find(x => x.name == filter_name).value = ''
        this.$refs.filtersAbonents.apply()
      },
      getFiltersOfComponents(){

        return this.$refs.filtersAbonents && this.$refs.filtersAbonents.filters.filter(x => x.value && x.value != '') || []
      },
      
      filterFormat() {
        this.countFilters = 0
        const temp = {};
        /**----------- СОРТУВАННЯ -----------*/
        if(this.options.sortBy[0] && this.options.sortBy[0] == 'street__street'){
          if (this.options.sortDesc[0]) temp.ordering = "street__street,house,letter,flat"
          else temp.ordering = "-street__street,-house,-letter,-flat"
        }else if(this.options.sortBy[0]){
          temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0]
          temp.ordering = temp.ordering + ",street__street,house,letter,flat"
        }
        /**-------------------------------- */
        
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        
        //if(this.search) temp.search = this.search
        
        
        /* получение значений фильтрации и прощет колличества */
        this.filtersComponents = this.getFiltersOfComponents()
        this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
        this.countFilters = this.filtersComponents.length

        if(this.search){
          //console.log(this.searchParse(this.search))
          Object.assign(temp, this.searchParse(this.search, this.searchGroupNow.name))
          
          /*if(this.checkTypeRequestBilling()){
            Object.assign(temp, await  this.searchParse2(this.search))
          }else Object.assign(temp, this.searchParse(this.search))*/
        } 
        //console.log(temp)
        return temp; 
      },
      
      goToGlobalSearch(){
        this.$router.push({ name: 'global_search', params: { search: this.search }})
      }

    },
  }
</script>

<style>
  .link-cursor {
    cursor: pointer !important
  }
  .row-cursor tr {
    cursor: pointer;
  }
  .td_background tr td:first-child{
    background: #eee
  }
  .td_background_dark tr td:first-child{
    background: rgb(88, 88, 88)
  }
  .handle {
  cursor: grab;
  }
  .handle-click {
    cursor: pointer;
  }
</style>
